@import "styles/theme.module.scss";

$header-fs: 3rem;
$header-mobile-fs: 2.1rem;
$text-fs: 1.5rem;

@mixin header-style {
  font-size: $header-fs;
  font-family: $playful;

  @media (min-width: 481px) and (max-width: 768px) {
    font-size: $header-mobile-fs;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
}

%button {
  background-color: $primary;
  border-radius: $border-radius-md;
  color: white;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: $playful;
}

.main-layout {
  padding: 0 10vw;
  margin-top: 3rem;
  margin-bottom: 6rem;

  h4 {
    @include header-style;
  }

  > div:not(.location-panel) {
    padding: 30px 40px;
  }

  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
}

.first-text {
  background-color: #fff;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: $text-fs;
  color: $secondary;
  font-family: $standard;
  text-align: center;

  box-shadow: inset 0px 0.5px 2px 0.5px lightgrey;

  button {
    @extend %button;
    margin-top: 1rem;
    height: 52px;
    font-size: 1.25rem;
  }
}

.second-text {
  background-color: $primary;
  font-size: $text-fs;
  padding: 30px 40px;
  color: white;
  font-family: $standard;

  h4 {
    color: white;
  }
}

.third-text {
  background-color: $yellow;
  font-size: $text-fs;
  padding: 30px 40px;
  color: $secondary;
  font-family: $standard;

  h5 {
    margin-top: 2rem;
    font-size: 2.2rem;
    font-family: $playful;

    @media (min-width: 481px) and (max-width: 768px) {
      font-size: 2rem;
    }

    @media (max-width: 480px) {
      font-size: 1.5rem;
    }
  }
}

.event-program-panel {
  background-color: $blue;
  font-size: $text-fs;
  padding: 30px 40px;
  color: white;
  font-family: $standard;

  p {
    margin-bottom: 1.5rem;
  }

  .arrow-highlight-left {
    margin-top: -70px;
    margin-right: 15px;

    transform: rotate(80deg) scale(120%);

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .event-program-all-button {
    @extend %button;
    font-size: 1.25rem;

    @media (max-width: 992px) {
      margin-bottom: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }
}

.contact-panel {
  background-color: white;
  padding-top: 6rem !important;

  box-shadow: inset 0px -0.5px 2px 0.5px lightgrey;

  h4 {
    color: $primary;
  }

  p {
    font-size: $text-fs;
    color: $secondary;
    font-family: $standard;
  }

  .contact-form {
    font-family: $standard;
    color: $secondary;

    button {
      @extend %button;
      font-size: 1rem;
    }
  }
}

.location-panel {
  p {
    font-family: $standard;
    font-size: 1.3rem;
    color: white;
    margin-bottom: 0.8rem;
  }

  width: calc(100% - 23px);
  margin-left: 12px;

  @media (max-width: 768px) {
    p {
      font-size: 1.25rem;
    }
  }

  .where-are-we {
    padding: 30px 40px;
    h4 {
      color: white;
    }
    h5 {
      color: white;
      margin-top: 1.8rem;
      font-size: 1.6rem;
      font-family: $playful;
      margin-bottom: 0.2rem;

      @media (min-width: 481px) and (max-width: 768px) {
        font-size: 1.4rem;
      }

      @media (max-width: 480px) {
        font-size: 1.4rem;
      }
    }

    background-color: $blue;
  }

  .map {
    background-color: white;
    position: relative;
    padding: 0;
  }
}

.calendar-panel {
  background-color: white;
  color: #212529;
  font-size: $text-fs;
  padding-top: 40px;
  padding-bottom: 20px;
  font-family: $playful;
}

.start-360-tour {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tour-container {
  position: relative;
  margin: auto;
}

.modal {
  font-family: $standard;
}

.themenwelt-hinweis {
  font-family: $playful;
  font-size: 30px;
}

.themenwelt-arrow {
  position: relative;
  left: -40px;
  top: 12px;
}

.roomtour-overlay {
  position: absolute;
  font-family: $playful;
  top: 50%;
  left: 50%;
  text-align: center;
  color: white;
  transform: translate(-50%, -50%);
  background-color: transparent;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .roomtour-icon {
    height: 80px;
    margin-bottom: 15px;
  }

  button {
    @extend %button;
    margin-top: 10px;
    font-size: 1rem;
  }
}

.disruptor-wrapper {
  z-index: 50;
  position: fixed;
  right: 1rem;

  @media (max-width: 778px) {
    bottom: 7rem;
    right: 1rem;
  }

  @media (min-width: 779px) {
    top: 8.5rem;
  }
}
