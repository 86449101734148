@import "styles/theme.module.scss";

.typeahead {
  width: 100%;
  > div {
    width: 100%;
    > input {
      width: 100%;
    }
  }
}

.form {
  margin-top: 50px;

  .hny {
    display: none;
  }

  input,
  select,
  textarea {
    height: 38px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    outline-color: $primary;
    margin-top: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
  }
  textarea {
    height: 200px;
  }

  .submit-success {
    padding: 15px;
    background: rgba($primary, 0.4);
    border-radius: 5px;
  }

  .submit-failed {
    padding: 15px;
    background: rgba(red, 0.3);
    border-radius: 5px;
  }

  .validation-warning {
    padding: 15px;
    background: rgba(yellow, 0.3);
    border-radius: 5px;
  }

  .floating-input {
    position: relative;
    /* label */
    > span {
      z-index: 1;
      padding: 0.8rem 0.7rem;
      position: absolute;
    }

    :global(.dropdown-item) {
      display: flex;
      grid-gap: 0.5rem;
      white-space: pre-wrap;
    }

    .icon {
      width: 1rem;
    }

    .address-line {
      color: rgb(108, 117, 125);
    }

    input {
      padding: 25px 10px;
      padding-top: 45px;
    }
  }

  select:invalid {
    color: gray;
  }
  select {
    option:first {
      color: #999;
    }
  }
  .required {
    color: red;
  }
}
