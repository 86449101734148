@import "styles/theme.module.scss";

.modal-window {
  position: fixed;
  background-color: fade-in(rgba(lightgrey, 0.7), 0);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12346;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  font-family: $standard;

  &.show {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  .modal-wrapper {
    max-height: 90vh;
    max-width: 90vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    background: white;
    overflow: auto;

    /* CSS specific to iOS devices */
    // @supports (-webkit-touch-callout: none) {
    //   overflow-y: scroll !important;
    //   -webkit-overflow-scrolling: touch !important;
    // }
  }

  header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  :global(body) {
    overflow: hidden;
  }

  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 0;
  width: 70px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: black;
  }
}
