.footer-container {
  height: 115px;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-family: "sofia-pro", medium;
}

.icon-wrapper {
  background: white;
  padding: 15px;
}

.offer-source {
  color: #707070;
  text-align: right;
}

.left-side {
  display: flex;
  position: absolute;
  background-color: #707070;
  gap: 1rem;
  left: 0px;
  font-size: 1.25rem;
  height: 100%;
  padding: 50px 100px 50px 50px;
  display: flex;
  background: #75c5bf;
  border-top-right-radius: 100%;
  width: 68vw;
  z-index: 2;
}

.left-side a {
  color: white !important;
}

.middle-section {
  width: 40vw;
  background-color: #7ab829;
  position: absolute;
  height: 100%;
  left: 25%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
}

.right-side {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 15px 100px 15px 50px;
  background-color: white;
  border-top-left-radius: 100%;
  z-index: 1;
  width: 50vw;
  min-width: 490px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, auto); 
  grid-gap: 10px; 

}

.logo-footer {
  max-width: 225px;
  min-height: 60px;
}

.mobile-links {
  display: none;
}

@media (max-width: 1600px) {
  .left-side{
    padding: 25px 100px 50px 50px !important;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(2, auto);
  }

  .grid-item:nth-child(1) {
    grid-row: 2;
    grid-column: 1;
  }
  .grid-item:nth-child(2) {
    grid-row: 2;
    grid-column: 2;
  }
  
  .grid-item:nth-child(3) {
    grid-row: 1;
    grid-column: 1;
  }
}

@media (min-width: 830px) and (max-width: 1000px) {
  .left-side {
    padding: 35px 100px 50px 50px !important;
    font-size: 1rem;
  }
}

@media (max-width: 830px) {
  .right-side {
    width: 70vw;
  }

  .mobile-links {
    display: inherit;
    color: black;
    display: flex;
    justify-content: flex-end;
  }

  .middle-section {
    left: -15px;
    min-width: 370px;
  }

  .left-side {
    display: none !important;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(2, auto);
  }

  .grid-item:nth-child(1) {
    grid-row: 2;
    grid-column: 2;
  }
  .grid-item:nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }
  
  .grid-item:nth-child(3) {
    grid-row: 1;
    grid-column: 2;
  }
}

@media (max-width: 500px) {
  .right-side {
    min-width: 550px;
  }
  .mobile-links {
    margin-left: 50px;
  }
}

