.desktop-nav-bar {
  font-family: sofia-pro, sans-serif;
  /* margin-right: 40px; */
  /* margin-left: 40px; */
  margin: 20px 40px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 778px) {
  .desktop-nav-bar {
    display: none !important;
  }
}

@media (min-width: 779px) {
  .mobile-nav-bar {
    display: none !important;
  }
}

.button {
  cursor: pointer;
  font-size: 0.8rem;
  color: white;
  border: none;
  padding: 7px 25px;
  border-radius: 5px;
  /* width: 180px; */
  font-family: "Anthology-Sans" !important;
  text-transform: uppercase;
}

.utils-button {
  background-color: #7ab829;
}

.login-button {
  background-color: #75c5bf;
}

.register-button {
  background-color: #eccf08;
}

.user-name {
  color: #696871;
  background: white;
}

.user-logo {
  height: 15px;
  vertical-align: bottom;
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.mobile-expand-icon {
  display: flex;
  align-items: center;
  padding: 20px 0px 20px 20px;
  margin: -20px 0px -20px -20px;
}

.mobile-expand-icon img {
  height: 20px;
}

.kita-logo {
  height: 51px;
}

.mobile-nav-bar .kita-logo {
  height: 40px;
}

.logo-background {
  position: absolute;
  width: 240px;
  top: 0;
  z-index: -1;
  transform: translateY(-50%);
}

.logo-foreground {
  position: relative;
  left: 50px;
  top: -10px;
  display: flex;
  grid-gap: 5px;
}

.action-bar {
  background: white;
  z-index: 999;

  margin: -20px -40px;
  padding-right: 25px;
  padding-left: 200px;
  border-bottom-left-radius: 100%;
}

.action-bar > * {
  position: relative;
  top: -10px;
}

.action-bar button {
  font-size: 1rem;
}

.green-circle {
  background-color: #f0e400;
  position: absolute;
  width: 230px;
  height: 100px;
  top: 5px;
  right: 167px;
  border-bottom-left-radius: 75%;
  border-bottom-right-radius: 25%;
}

.yellow-circle {
  right: 0px;
  border-bottom-left-radius: 70%;
  background-color: #7ab829;
  position: absolute;
  width: 230px;
  height: 100px;
}

.right-header-image {
  position: absolute;
  width: 777px;
  right: -60px;
  top: -663px;
}

.mobile-nav-bar {
  position: sticky;
  top: 0;
  z-index: 100;
}

.tools-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
}

.tools-container .tools-icon {
  height: 50px;
}

.chevron-icon {
  height: 15px;
  transition-duration: 0.5s;
  transition-property: transform;
}

.chevron-icon:not(.opened) {
  transform: translateY(10%);
}

.chevron-icon.opened {
  transform: rotate(180deg);
}

.logout-icon {
  width: 25px !important;
}

.tools-dropdown {
  position: absolute;
  top: 40px;
  left: -45px;
  width: 200px;
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
  border-radius: 5px;
  grid-gap: 10px;
  background: white;
  padding: 10px 0px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  display: none;
  text-transform: uppercase;
}

.service-item:hover {
  color: black;
}

.service-item {
  color: #696871;
  text-decoration: none;

  span {
    margin-right: 10px !important;
  }
}

.tools-dropdown.opened {
  display: inherit;
}

.tools-dropdown div {
  cursor: pointer;
  padding: 10px;
  height: 40px;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  font-family: "Anthology-Sans" !important;
}

.tools-dropdown .logout-button {
  cursor: pointer;
  padding: 10px;
  height: 40px;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  font-family: "Anthology-Sans" !important;
  text-decoration: none;
  color: #696871;
}

.tools-dropdown div:hover {
  background-color: lightgrey;
}

.tools-dropdown div img {
  width: 25px;
  max-width: 40px;
  margin-right: 10px;
}

/* Style the navigation menu */
.topnav {
  /* overflow: hidden; */
  background-color: white;
  position: relative;
  /* padding: 14px 16px; */

  box-shadow: 0 4px 4px -2px lightgrey;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.links {
  display: none;

  box-shadow: 0 4px 4px -2px lightgrey;

  position: absolute;
  top: 85px;
  width: 100vw;
  z-index: 3;
  padding: 15px;
  background: white;
}

.links.opened {
  display: inherit;
}

.links a {
  padding: 10px;
}

/* Style navigation menu links */
.topnav a {
  background: white;
  color: #696871;
  text-decoration: none;
  font-size: 1rem;
  display: block;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  background-color: white;
  color: black;
}

/* Style the active link (or home/logo) */
.active {
  background-color: white;
  color: white;
}

.show {
  display: inherit;
}

.hide {
  display: none;
}
