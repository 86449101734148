$slider-margin: 12px;

.main-layout {
  position: relative;
}

%nav-button {
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, opacity 0.3s ease;
  background-position: center;
  opacity: 0.9;
}
.prev-button {
  @extend %nav-button;
  left: 30px;
  background-image: url("/images/chevron-small-left.svg");

  @media (max-width: 480px) {
    left: -40px;
    width: 50px;
    height: 50px;
  }
}
.next-button {
  @extend %nav-button;
  right: 30px;
  background-image: url("/images/chevron-small-right.svg");

  @media (max-width: 480px) {
    right: -40px;
    width: 50px;
    height: 50px;
  }
}

.prev-button:hover,
.next-button:hover {
  transform: translateY(-50%) scale(1.2);
  opacity: 1;
}

.slide-wrapper {
  margin-left: $slider-margin;
  margin-right: $slider-margin;
}

.carousel-image {
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  max-width: 600px;
  height: 300px;

  @media (min-width: 480px) and (max-width: 670px) {
    max-width: 300px;
    height: 200px;
  }

  @media (max-width: 479px) {
    max-width: 220px;
    height: 146px;
  }
  // @media (min-width: 630px) and (max-width: 869px) {
  //   max-width: 400px;
  //   height: 266px;
  // }
  // @media (min-width: 500px) and (max-width: 629px) {
  //   max-width: 300px;
  //   height: 200px;
  // }
  // @media (max-width: 499px) {
  //   max-width: 220px;
  //   height: 146px;
  // }
}
