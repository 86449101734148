@import "styles/theme.module.scss";

%button {
  background-color: $primary;
  border-radius: $border-radius-md;
  font-family: $playful;
  color: white;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
}

.modal {
  font-family: $standard;

  .program-modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .program-modal-subtitle {
      margin-top: -1rem;
    }

    .program-modal-date {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 5px;
    }

    .program-modal-referee {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 5px;

      .program-modal-referee-label {
        grid-column: 1;
      }
      .program-modal-referee-value {
        grid-column: 2;
      }
    }

    .program-modal-link {
      @extend %button;
    }

    .program-modal-alternative {
      font-size: 0.95rem;
    }
  }

  .bottom-wrapper {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 25px;
      align-items: start;
    }
  }

  .badge-wrapper {
    margin-top: -50px;
    display: flex;
    gap: 10px;

    @media (max-width: 600px) {
      margin-top: 0px;
    }
  }

  .badge {
    width: 100px;

    @media (max-width: 600px) {
      width: 85px;
    }
  }
}
