@import "styles/theme.module.scss";

.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 15.5rem;
  width: 20rem;
  color: white;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  font-family: $playful;

  @media (max-width: 778px) {
    height: 10rem;
    width: 15rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

%pulse-banner {
  color: white;
  padding: 0.15rem 0.3rem;
  background-color: $red;
  border-radius: 5px;
  animation: pulse 1.5s infinite;
}

.collapsed-banner {
  @extend %pulse-banner;
  font-size: 1.4rem;
  cursor: pointer;

  @media (max-width: 778px) {
    font-size: 1.2rem;
  }
}

.live-banner {
  @extend %pulse-banner;
  z-index: 2;
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 0.8rem;
}

.collapse-button {
  z-index: 3;
  position: absolute;
  top: 0.6rem;
  right: 1rem;
  height: 35px;
  width: 35px;
  background-color: $yellow;
  border-radius: 5px;
  background-image: url("../../../public/images/external-link-white.svg");
  background-size: auto 60%;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;

  @media (max-width: 778px) {
    height: 30px;
    width: 30px;
  }
}
.collapse-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.stream-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.stream-teaser-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background-color: black;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .teaser {
    display: flex;
    flex-direction: column;
  }

  iframe {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .teaser-image {
    z-index: 1;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../public/images/kita-hub-logo-felder.svg");
    background-size: auto 80%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.6;
    filter: blur(3px);
  }

  .teaser-title {
    margin-top: 5%;
    z-index: 2;
    font-size: 1.75rem;
    opacity: 1;

    @media (max-width: 778px) {
      font-size: 1.1rem;
      margin-top: 15%;
    }
  }

  .teaser-countdown {
    z-index: 2;
    opacity: 1;
    background-color: $blue;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;

    font-family: $standard;
    font-size: 1rem;

    @media (max-width: 778px) {
      font-size: 0.8rem;
      padding: 0.15rem 0.4rem;
    }
  }
}

.description-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: $blue;
  font-family: $standard;
  bottom: 0;

  .description-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .title {
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1.4rem;

      @media (max-width: 778px) {
        font-size: 0.9rem;
      }
    }

    .subtitle {
      font-size: 0.97rem;
      line-height: 1.1rem;

      @media (max-width: 778px) {
        display: none;
      }
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    .expand-button {
      height: 50px;
      width: 50px;
      border-radius: 5px;
      background-image: url("../../../public/images/solar_play-circle-bold.svg");
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.3s ease;

      @media (max-width: 778px) {
        height: 35px;
        width: 35px;
        background-size: auto 100%;
      }
    }
  }
}

.expand-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}
