@import "styles/theme.module.scss";

.main-layout {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 13px;
  border-radius: 5px;
  background-color: white;
  font-family: $playful;
  transition: transform 0.3s ease;
  overflow: hidden;

  .title {
    color: $secondary;
    font-size: 1.5rem;
    line-height: 1;
    word-break: break-word;

    @media (max-width: 480px) {
      font-size: 1.3rem;
    }
  }
  .modal-button {
    font-size: 1.2rem;
    display: none;
    border-radius: 7.5px;
    text-transform: uppercase;
    width: 100%;
    border: none;
    text-align: left;
  }
}

.main-layout:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: scale(1.02);
  .title {
    display: none;
  }

  .modal-button {
    display: block;
  }
}

.main-layout:hover {
  cursor: pointer;
}

.calendar {
  background-color: $secondary;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 0;

  @media (max-width: 480px) {
    height: 54px;
    width: 54px;
  }

  .day {
    font-size: 2.25rem;
    line-height: 1;

    @media (max-width: 480px) {
      font-size: 1.8rem;
    }
  }

  .month {
    font-size: 1.3rem;
    line-height: 1;

    @media (max-width: 480px) {
      font-size: 1.1rem;
    }
  }
}
