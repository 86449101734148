@import "styles/theme.module.scss";

.root {
  .title {
    color: #707070;
    font-family: $playful;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(75px + 20px);
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: pre-line;

    transition: color 0.2s;
  }

  .pic {
    padding: 15px;
    width: 100%;
    transition: opacity 0.2s;
    margin-top: -10px;
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .content {
    opacity: 0;
  }

  background-color: white;
  border-radius: 5px;
  height: calc(100% - 10px);
}

.root:hover {
  .pic {
    opacity: 0 !important;
    display: none;
  }

  .content {
    opacity: 1;
  }
}

.divider {
  height: 4px;
  border-radius: 25%;
  margin-left: 10px;
  margin-right: 10px;
}

.content {
  font-family: $standard;
  font-size: 0.8rem;
  text-align: center;
  padding: 5px;
  color: #696871;

  transition: opacity 0.2s;

  display: flex;
  flex-direction: column;

  height: 100%;
  justify-content: space-between;
}

.to-themenwelt {
  margin-top: 10px;
  border-radius: 7.5px;
  text-transform: uppercase;
  color: white;
  width: 100%;
  padding: 5px;
  border: none;
}
